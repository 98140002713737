import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { ContextLoaderProvider } from "src/context/ContextLoader";
import Welcome from "src/components/pages/welcome";
import MainLayout from "src/layouts/MainLayout";
import BaseLayout from "src/layouts/BaseLayout";
import ReimbursementsClosing from "src/pages/collection/ReimbursementsClosing";
import ClosingMenu from "src/pages/Reimbursement/Closing/ClosingMenu";
import ReimbursementContext from "src/pages/Reimbursement/Closing/reimbursementContext";
import PaymentsClosing from "src/pages/collection/PaymentsClosing";
import { AppProvider } from "src/pages/Reimbursement/appContext";
import { getUserData } from "src/utils/msal";
import { PageNotFound } from "src/components/pages";

const routeAccessByRole = {
  reimbursementsClosing: new Set(["ESENCIAL-BO_ISA_ADMIN", "ESENCIAL-BO_ISA_CIERRE"]),
  reimbursementsPayment: new Set(["ESENCIAL-BO_ISA_ADMIN", "ESENCIAL-BO_ISA_PAGO"])
}

const isAuthorizedRouteAccessByRole = (authorizedRoles: Set<string>) => {
  const user = getUserData()
  
  if (authorizedRoles.size === 0) {
    return false
  }
  
  if (user.roles === undefined) {
    return false
  }
  return user.roles.some((role: string) => authorizedRoles.has(role))
}

const RoutesConfig = () => {
  const isAuthenticated = useIsAuthenticated();
  // const userRoles = getUserRoles();

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <ContextLoaderProvider>
          <Routes>
            <Route element={<BaseLayout />}>
              {isAuthenticated ? (
                <Route element={<AppProvider><MainLayout /></AppProvider>}>
                  <Route path="/" element={<Welcome isAuthorized={true} />} />

                  <Route path="reimbursements">
                    <Route
                      path="closing"
                      element={
                        isAuthorizedRouteAccessByRole(routeAccessByRole.reimbursementsClosing) ?
                          (<ReimbursementContext><ReimbursementsClosing /></ ReimbursementContext>) :
                          (<Welcome isAuthorized={false} />)
                      } />
                    <Route
                      path="payments"
                      element={
                        isAuthorizedRouteAccessByRole(routeAccessByRole.reimbursementsPayment) ?
                          (<PaymentsClosing />) :
                          (<Welcome isAuthorized={false} />)
                      } />
                    <Route path="entry" element={<br />} />
                    <Route path="anullement" element={<br />} />
                  </Route>

                  <Route path="imed">
                    <Route path="folios" element={<br />} />
                    <Route path="proveedores" element={<br />} />
                  </Route>

                  <Route path="*" element={<PageNotFound />} />
                </Route>
              ) : (
                <Route path="*" element={<Welcome isAuthorized={false} />} />
              )}
            </Route>
          </Routes>
        </ContextLoaderProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export default RoutesConfig;
