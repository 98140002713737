import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Data } from 'src/interfaces/ReimbursementInterface/Data.interface';
import { GroupedData } from "src/interfaces/ReimbursementInterface/GroupedData.interface";
import { getCandidates } from 'src/services/backoffice/reimbursementsServices';
import axios from 'axios'
import { useAppContext } from '../appContext';


interface sumaContextType {
    // costoTotalAcumulado: number;
    // beneficioTotalAcumulado: number;
    // cantidadPrestaciones: number;
    loading: boolean,
    error: boolean,
    dataReimbursements: GroupedData[];
    // setCostoTotalAcumulado: (sum: number) => void;
    // setBeneficioTotalAcumulado: (sum: number) => void;
    // setCantidadPrestaciones: (sum: number) => void;
    setLoading: (load: boolean) => void;
    setError: (error: boolean) => void;
    setDataReimbursements: (dat: GroupedData[]) => void;
    getData: (date?: string | null) => Promise<void>;
    dataDashboard: dataDashboardInterface
}

const SumaContext = React.createContext<sumaContextType>({
    // costoTotalAcumulado: 0,
    // beneficioTotalAcumulado: 0,
    // cantidadPrestaciones: 0,
    dataReimbursements: [],
    loading: true,
    error: false,
    // setCostoTotalAcumulado: () => {},
    // setBeneficioTotalAcumulado: () => {},
    // setCantidadPrestaciones: () => {},
    setLoading: () => {},
    setError: () => {},
    setDataReimbursements: () => {},
    getData: async () => {},
    dataDashboard: {} as dataDashboardInterface
});

export const useSum = () => useContext(SumaContext);

export interface dataDashboardInterface {
    cantidadPrestaciones: number
    costoTotalAcumulado: number
    beneficioAcumulado: number
    cantidadReembolsos: number
    dataReimbursements: GroupedData[]
}

const ReimbursementContext: React.FC<{ children: React.ReactNode }> = ({ children })  => {
    const [costoTotalAcumulado, setCostoTotalAcumulado] = useState(0);
    const [loading, setLoading] = useState(true);
    const [beneficioTotalAcumulado, setBeneficioTotalAcumulado] = useState(0);
    const [cantidadPrestaciones, setCantidadPrestaciones] = useState(0);
    const [dataReimbursements, setDataReimbursements] = useState<GroupedData[]>([]);
    const [error, setError] = useState(false);
    const {showView, resetView} = useAppContext();

    

    useEffect(() => {
        if(!showView){
            setDataReimbursements([])
            setLoading(true)
            setCostoTotalAcumulado(0)
            setBeneficioTotalAcumulado(0)
            setCantidadPrestaciones(0)
            setError(false)
        }
    }, [showView]);

    const getData = async (date?: string | null) => {
        try {
            const fetchData = await getCandidates(date);
            setDataReimbursements(groupReimbursementsByFolio(fetchData));
            // axios.get("/_mock_reimbursements_data.json").then((res) => {
            //     setDataReimbursements(groupReimbursementsByFolio(res.data.data));
            //   });
            setError(false)
        } catch (err) {
            resetView()
            setError(true)
        }
        setLoading(false)
    }

    const dataDashboard: dataDashboardInterface = {
        cantidadPrestaciones: cantidadPrestaciones,
        beneficioAcumulado: beneficioTotalAcumulado,
        costoTotalAcumulado: costoTotalAcumulado,
        cantidadReembolsos: dataReimbursements.length,
        dataReimbursements: dataReimbursements
    }

    

    const groupReimbursementsByFolio = (data: Data[]): GroupedData[] => {

        const { groupedData, sumTotals } = data.reduce((acc, currentCase) => {
            const { settlement_folio, per_benefit_cost, benefit_quantity, base_bonus_amount, additional_bonus_amount, reimbursement_status, request_date, comptroller_email } = currentCase;

            if (!acc.groupedData[settlement_folio]) {
                acc.groupedData[settlement_folio] = {
                    cases: [],
                    costo_total: 0,
                    beneficio_total: 0,
                    estado: undefined,
                    request_date: undefined,
                    comptroller_email: undefined,
                };
            }

            acc.groupedData[settlement_folio].cases.push(currentCase);
            acc.groupedData[settlement_folio].costo_total += per_benefit_cost * benefit_quantity;;
            acc.groupedData[settlement_folio].beneficio_total += base_bonus_amount + additional_bonus_amount;
            acc.groupedData[settlement_folio].estado = reimbursement_status;
            acc.groupedData[settlement_folio].request_date = request_date;
            acc.groupedData[settlement_folio].comptroller_email = comptroller_email;

            acc.sumTotals.costo_total_acumulado += per_benefit_cost * benefit_quantity;
            acc.sumTotals.beneficio_total_acumulado += base_bonus_amount + additional_bonus_amount;

            return acc;
        }, {
            groupedData: {} as { [key: string]: { cases: Data[], costo_total: number, beneficio_total: number, estado?: string, request_date?: string, comptroller_email?: string } },
            sumTotals: { costo_total_acumulado: 0, beneficio_total_acumulado: 0, cantidad_prestaciones: 0 }
        });

        const result = Object.keys(groupedData).map(settlement_folio => ({
            settlement_folio,
            count: groupedData[settlement_folio].cases.length,
            cases: groupedData[settlement_folio].cases,
            costo_total: groupedData[settlement_folio].costo_total,
            beneficio_total: groupedData[settlement_folio].beneficio_total,
            estado: groupedData[settlement_folio].estado,
            request_date: groupedData[settlement_folio].request_date,
            comptroller_email: groupedData[settlement_folio].comptroller_email,
        }));

        

        setCostoTotalAcumulado(sumTotals.costo_total_acumulado);
        setBeneficioTotalAcumulado(sumTotals.beneficio_total_acumulado);
        setCantidadPrestaciones(data.length);

        

        return result;
    };

    return (
        <SumaContext.Provider value={{loading, error, dataReimbursements, setDataReimbursements, getData, setLoading, setError, dataDashboard}}>
            {children}
        </SumaContext.Provider>
    );
}

export default ReimbursementContext;