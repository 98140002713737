const font = {
    fontFamily: "Interstate"
}

const name = {
    ...font,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    letterSpacing: "0.0120588em",
    color: "#FFFFFF"
}

const job = {
    ...font,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    letterSpacing: "0.0120588em",
    color: "#FFFFFF",
    alignItems: "center"
}

const toolbar = {
    backgroundColor: "#01736C"
}

const boxHeader = {
    boxShadow: "none",
    flexGrow: 1,
    marginBottom: 0
}

const divApp = {
    boxShadow: "none",
    bgColor: "transparent"
}

const toolbarStyle = {
    boxShadow: "none",
    height: "72px"
}

const titleContenedor = {
    width: "100%",
    display: "flex",
    alignItems: "center"
}

const cerrarSesion = {
    ...font,
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    letterSpacing: "0.0120588em",
    color: "#FFFFFF",
    fill: "#FFFFF",
    cursor: "pointer",
    margin: 0, 
    transition: "color fill 0.15s",
    paddingLeft: "32px",
    paddingRight: "22px"
}


export {
    name,
    job,
    toolbar,
    boxHeader,
    divApp,
    toolbarStyle,
    cerrarSesion,
    titleContenedor,
}
