import React, { useState } from "react";
import {
    Table, TableBody, TableContainer, TableHead, TableRow,
    TablePagination, Paper, Checkbox, Toolbar, IconButton, Tooltip,
    TableSortLabel, Typography, Collapse, Box,
    Fade,
    CircularProgress,
    TextField
} from "@mui/material";
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { GroupedData } from "src/interfaces/ReimbursementInterface/GroupedData.interface";
import SendIcon from '@mui/icons-material/Send';
import "./reimbursementsTable.css"
import ConfirmationDialog from "./alertDialog/confirmationDialog";
import createReimbursements from "src/services/backoffice/createReimbursements";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        borderTop: "1px solid #d3d3d3",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

type Order = 'asc' | 'desc';

const ReimbursementsTable = ({ cases, loading, reset }: { cases: GroupedData[], loading: boolean, reset: () => void }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [selected, setSelected] = useState<string[]>([]);
    const [searchFolio, setSearchFolio] = useState('');
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof GroupedData>('settlement_folio');
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [idCreateReimbursements, setIdCreateReimbursements] = useState<string[]>([])

    const handleCheckboxClick = (event: React.MouseEvent, settlement_folio: string) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(settlement_folio);
        const newSelected = selectedIndex === -1
            ? [...selected, settlement_folio]
            : selected.filter(folio => folio !== settlement_folio);

        setSelected(newSelected);

        const selectedGroupIds = sortedData
            .filter(group => newSelected.includes(group.settlement_folio))
            .flatMap(group => group.cases.map(caso => caso.id));

        const allSelectedIds = Array.from(new Set(selectedGroupIds));
        setIdCreateReimbursements(allSelectedIds);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = sortedData.map((group) => group.settlement_folio);
            setSelected(newSelecteds);

            const allGroupIds = sortedData.flatMap(group => group.cases.map(caso => caso.id));

            const allSelectedIds = Array.from(new Set(allGroupIds));
            setIdCreateReimbursements(allSelectedIds);
        } else {
            setSelected([]);
            setIdCreateReimbursements([]);
        }
    };

    const handleRequestSort = (property: keyof GroupedData) => {
        const isAscending = orderBy === property && order === 'asc';
        setOrder(isAscending ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const isSelected = (settlement_folio: string) => selected.indexOf(settlement_folio) !== -1;

    interface columnInterface {
        id: string,
        label: string | React.ReactNode,
        alignment?: "left" | "center" | "right" | "justify" | "inherit"
    }

    const columns: Array<columnInterface> = [
        {
            id: "select", label: (
                <Checkbox
                    indeterminate={selected.length > 0 && selected.length < cases.length}
                    checked={cases.length > 0 && selected.length === cases.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all settlements' }}
                />
            ), alignment: "left"
        },
        { id: "settlement_folio", label: "folio", alignment: "left" },
        { id: "request_date", label: "fecha de solicitud", alignment: "left" },
        { id: "comptroller_email", label: "contralor", alignment: "left" },
        { id: "estado", label: "estado", alignment: "left" },
        { id: "count", label: "cantidad prestaciones", alignment: "right" },
        { id: "costo_total", label: "costo total", alignment: "right" },
        { id: "beneficio_total", label: "bonificación total", alignment: "right" },
        { id: "detalles", label: "", alignment: "right" }
    ];

    const filteredData = cases.filter(group =>
        group.settlement_folio.toLowerCase().includes(searchFolio.toLowerCase())
    );

    const sortedData = filteredData.sort((a, b) => {
        if (orderBy === 'settlement_folio' || orderBy === 'estado') {
            return order === 'asc'
                ? a.settlement_folio.localeCompare(b.settlement_folio)
                : b.settlement_folio.localeCompare(a.settlement_folio)
        } else {
            const valueA = a[orderBy] as number;
            const valueB = b[orderBy] as number;

            return order === 'asc'
                ? valueA - valueB
                : valueB - valueA;
        }
    });

    return (
        <>
            <Box width="100%" >
                <Paper>
                    <EnhancedTableToolbar numSelected={selected.length} searchFolio={searchFolio} setSearchFolio={setSearchFolio} idCreateReimbursements={idCreateReimbursements} reset={reset} />

                    {loading ? (

                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box height={400} display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
                                <Fade
                                    in={loading}
                                    style={{
                                        transitionDelay: loading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <p>Cargando...</p>
                            </Box>
                        </Box>


                    ) : (
                        <>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <StyledTableCell
                                                    key={column.id}
                                                    sortDirection={orderBy === column.id ? order : false}
                                                    align={column.alignment}
                                                >
                                                    {column.id !== 'detalles' ? (
                                                        <TableSortLabel
                                                            active={orderBy === column.id}
                                                            direction={orderBy === column.id ? order : 'asc'}
                                                            onClick={() => handleRequestSort(column.id as keyof GroupedData)}
                                                        >
                                                            {column.label}
                                                        </TableSortLabel>
                                                    ) : (
                                                        column.label
                                                    )}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((group, index) => {
                                            const isItemSelected = isSelected(group.settlement_folio);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            const isExpanded = expandedRow === group.settlement_folio;
                                            return (
                                                <React.Fragment key={group.settlement_folio}>
                                                    <StyledTableRow
                                                        sx={{ backgroundColor: index % 2 === 0 ? 'action.hover' : 'inherit' }}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        selected={isItemSelected}
                                                        tabIndex={-1}
                                                    >
                                                        <StyledTableCell align={columns[0].alignment}>
                                                            <Checkbox
                                                                checked={isItemSelected}
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                onClick={(event) => handleCheckboxClick(event, group.settlement_folio)}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell align={columns[1].alignment}>{group.settlement_folio}</StyledTableCell>
                                                        <StyledTableCell align={columns[2].alignment}>{dayjs(group.request_date).format("DD/MM/YYYY")}</StyledTableCell>
                                                        <StyledTableCell align={columns[3].alignment}>{group.comptroller_email}</StyledTableCell>
                                                        <StyledTableCell align={columns[4].alignment}>{group.estado}</StyledTableCell>
                                                        <StyledTableCell align={columns[5].alignment}>{group.count}</StyledTableCell>
                                                        <StyledTableCell align={columns[6].alignment}>${new Intl.NumberFormat().format(group.costo_total)}</StyledTableCell>
                                                        <StyledTableCell align={columns[7].alignment}>${new Intl.NumberFormat().format(group.beneficio_total)}</StyledTableCell>
                                                        <StyledTableCell align={columns[8].alignment}>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                onClick={() => setExpandedRow(isExpanded ? null : group.settlement_folio)}
                                                            >
                                                                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                    {/* <Box display="table-row" > */}
                                                    <TableRow>
                                                        <StyledTableCell style={{ padding: 0 }} colSpan={columns.length}>
                                                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                                                <Box my={2}>
                                                                    <TableContainer>
                                                                        <Table aria-label="details" sx={{ border: '1px dashed #c3c3c3' }}>
                                                                            <TableHead sx={{ borderBottom: '1px solid #d3d3d3' }}>
                                                                                <StyledTableRow sx={{ height: "2rem" }}>
                                                                                    <StyledTableCell align="left" >fecha de consumo</StyledTableCell>
                                                                                    <StyledTableCell align="right">código de prestación</StyledTableCell>
                                                                                    <StyledTableCell align="left">descripción</StyledTableCell>
                                                                                    <StyledTableCell align="right">RUT prestador</StyledTableCell>
                                                                                    <StyledTableCell align="left">nombre prestador</StyledTableCell>
                                                                                    <StyledTableCell align="left">tipo documento tributario</StyledTableCell>
                                                                                    <StyledTableCell align="right">número documento tributario</StyledTableCell>
                                                                                    <StyledTableCell align="right">cantidad prestaciones</StyledTableCell>
                                                                                    <StyledTableCell align="right">costo</StyledTableCell>
                                                                                    <StyledTableCell align="right">bonificación</StyledTableCell>
                                                                                </StyledTableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {group.cases.map((reimbursement, index) => (
                                                                                    <StyledTableRow sx={{ backgroundColor: index % 2 === 0 ? 'action.hover' : 'inherit', height: "2rem" }} key={reimbursement.id}>
                                                                                        <StyledTableCell align="left" component="th" scope="row" > {dayjs(reimbursement.benefit_date).format("DD/MM/YYYY")}</StyledTableCell>
                                                                                        <StyledTableCell align="right">{reimbursement.benefit_code}</StyledTableCell>
                                                                                        <StyledTableCell align="left">{reimbursement.benefit_description}</StyledTableCell>
                                                                                        <StyledTableCell align="right">{reimbursement.provider_rut}</StyledTableCell>
                                                                                        <StyledTableCell align="left">{reimbursement.provider_name}</StyledTableCell>
                                                                                        <StyledTableCell align="left">{reimbursement.tax_document_type}</StyledTableCell>
                                                                                        <StyledTableCell align="right">{reimbursement.tax_document_number}</StyledTableCell>
                                                                                        <StyledTableCell align="right">{reimbursement.benefit_quantity}</StyledTableCell>
                                                                                        <StyledTableCell align="right">${new Intl.NumberFormat().format((reimbursement?.per_benefit_cost ?? 0) * (reimbursement?.benefit_quantity ?? 0))}</StyledTableCell>
                                                                                        <StyledTableCell align="right">${new Intl.NumberFormat().format((reimbursement?.base_bonus_amount ?? 0) + (reimbursement?.additional_bonus_amount ?? 0))}</StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Box>
                                                            </Collapse>
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                component="div"
                                count={sortedData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(event, newPage) => setPage(newPage)}
                                onRowsPerPageChange={(event) => {
                                    setRowsPerPage(parseInt(event.target.value, 10))
                                    setPage(0)
                                }}
                                labelRowsPerPage="Filas por página"
                                labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`; }}
                            />
                        </>
                    )}
                </Paper>
            </Box>
        </>
    );
};

interface EnhancedTableToolbarProps {
    numSelected: number;
    searchFolio: string;
    setSearchFolio: (folio: string) => void;
    idCreateReimbursements: string[];
    reset: () => void
}



const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {

    const { numSelected, searchFolio, setSearchFolio, idCreateReimbursements, reset } = props;
    const [closingDialogState, setClosingDialogState] = useState(false);
    const [anullementDialogState, setAnullementDialogState] = useState(false);

    
    const handleClose = () => {
        setClosingDialogState(false)
    }

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        theme.palette.action.selected,
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} seleccionados
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Listado de reembolsos para cierre
                </Typography>
            )}
            {numSelected > 0 ? (
                <>
                    <Tooltip title="Cerrar reembolsos">
                        <IconButton onClick={() => setClosingDialogState(true)}>
                            <SendIcon />
                        </IconButton>
                    </Tooltip>

                    <ConfirmationDialog passKey={"cerrar"}
                        callback={() => {
                            return createReimbursements(idCreateReimbursements)
                        }}
                        reset={reset}
                        open={closingDialogState}
                        onClose={() =>
                            handleClose()
                        }
                    >
                    </ConfirmationDialog>
                </>
            ) : (
                <TextField
                    label="Buscar por folio"
                    value={searchFolio}
                    onChange={(e) => setSearchFolio(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
            )}
        </Toolbar>
    );
};

export default ReimbursementsTable;
