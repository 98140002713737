/* istanbul ignore file */
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "../services/auth/authConfig";
import constants from "src/constants/_constants";

const msalInstance = new PublicClientApplication(msalConfig);

const roles = constants.REACT_APP_AZURE_ALLOWED_ROLES || [];

export const useVerifyRoles = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  let isAuthorized = false;
  let intersection: string[] = [];

  if (isAuthenticated) {
    const currentAccount = instance.getActiveAccount();

   // console.info(currentAccount?.idTokenClaims);
    
    if (
      currentAccount &&
      currentAccount?.idTokenClaims?.hasOwnProperty("roles") &&
      currentAccount.idTokenClaims.roles
    ) {
      // intersection = roles?.filter((role) =>
      //   // currentAccount?.idTokenClaims?.roles?.includes(role)
      // );
      intersection=currentAccount?.idTokenClaims?.roles?.map(role => role.replace("ESENCIAL-BO_ISA_", ""));
    }
    // console.info(intersection);

    if (intersection.length > 0) {
      isAuthorized = true;
    } else {
      isAuthorized = false;
    }
  }

  return { isAuthorized, intersection };
};

export const getUserName = () => {
  let userName: string = "No User";
  let currentAccount = msalInstance.getActiveAccount();
  if (currentAccount?.idTokenClaims?.hasOwnProperty("name")) {
    userName = currentAccount?.idTokenClaims?.name || "No Name";
  }

  return userName;
};

export const getUserData = () => {
  const activeAccount = msalInstance.getActiveAccount();

  return {
    name: activeAccount?.idTokenClaims?.name,
    email: activeAccount?.username,
    roles: activeAccount?.idTokenClaims?.roles
  }

}

export const getAccount = () => {
  msalInstance.addEventCallback((event: any) => {
    // console.log(event);
    if (
      event.eventType === EventType.LOGIN_SUCCESS &&
      event?.payload?.account
    ) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
    if (event.eventType === EventType.LOGIN_FAILURE) {
      console.log(JSON.stringify(event));
    }
  });

  return msalInstance;
};

export const getAccessToken = async () => {
  const account = msalInstance.getAllAccounts()[0];

  if (account) {
    const accessTokenRequest = {
      scopes: [process.env.REACT_APP_AZURE_SPA_SCOPE],
      account: account,
    };
    const tokenResponse = await msalInstance.acquireTokenSilent(
      accessTokenRequest
    );

    return tokenResponse.accessToken;
  } else {
    console.warn(
      "No active account detected. Please log in before use this function."
    );
  }
};

export const getFreshAccessToken = async () => {
  const account = msalInstance.getAllAccounts()[0];

  if (account) {
    const accessTokenRequest = {
      scopes: [process.env.REACT_APP_AZURE_SPA_SCOPE],
      account: account,
      forceRefresh: true,
    };

    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
      return tokenResponse.accessToken;
    } catch (error) {
      console.error("Silent token acquisition failed: ", error);
    }
  } else {
    console.warn("No active account detected. Please log in before using this function.");
  }
};

export const getUserRoles = () => {
  const currentAccount = msalInstance.getActiveAccount();
  let userRoles: string[] = [];

  if (
    currentAccount &&
    currentAccount?.idTokenClaims?.hasOwnProperty("roles")
  ) {
    userRoles = currentAccount?.idTokenClaims?.roles || [];
  }
  return userRoles;
};

// Función para obtener la imagen del perfil del usuario desde Microsoft Graph
export const getUserImage = async () => {
  console.log('ejecutando obtencion de la imagen del perfil del usuario');
  const currentAccount = msalInstance.getActiveAccount();
  if (currentAccount) {
    const token = await getAccessToken();
    // console.log('token',token);
    const profileResponse = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (profileResponse.ok) {
      const blob = await profileResponse.blob();
      return URL.createObjectURL(blob);
    } else {
      console.error('Error al obtener la imagen del perfil del usuario');
      return null;
    }
  } else {
    console.warn('No se ha detectado ninguna cuenta activa. Por favor, inicia sesión antes de utilizar esta función.');
    return null;
  }
};
