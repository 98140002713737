import { createTheme } from "@mui/material/styles";

/*
  Para editar un componente en especifico siempre se sigue la misma estructura, 
  dentro de root sobrescribiremos componente.
  
  components: {
    MuiTypography: {
        styleOverrides: {
          root: {

  Para sobrescribir un clase existente se puede hacer de esta forma:

  "&.ml-button": {}

  si la clase no existe, se creara para poder ser utilizada en el componente en especifico
  con la propiedad className.
*/

const theme = createTheme({
  palette: {
    primary: {
      main: "#00837A",
    },
    secondary: {
      main: "#FFFFFF",
      dark: "#FFFFFF",
      light: "#FFFFFF",
    },
    background: {
      default: "#F2F5F9",
    },
  },
  typography: {
    fontFamily: "Interstate",
    h1: {
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "24px",
      letterSpacing: "0.0120588em",
      color: "#203442",
    },
    h2: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "32px",
      letterSpacing: "0.0120588em",
      color: "#203442",
    },
    h3: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#364855",
      letterSpacing: "0.0120588em",
    },
    h4: {
      fontStyle: "normal",
      color: "#203442",
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: "36px",
      letterSpacing: "0.0120588em",
    },
    h5: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.0120588em",
      color: "#364855",
    },
    h6: {
      color: "#364855",
      fontWeight: 400,
      lineHeight: "20px",
      fontSize: "16px",
      letterSpacing: "0.0120588em",
    },
    body1: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "16px",
      letterSpacing: "0.0120588em",
      color: "#63717B",
    },
    body2: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "0.0120588em",
      color: "#203442",
    },

    subtitle1: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.0120588em",
      color: "#4C4C4C",
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "16px",
      letterSpacing: "0.0120588em",
      color: "#55798F",
    },
    caption: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "32px",
      letterSpacing: "0.0120588em",
      color: "#364855",
    },
    overline: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.0120588em",
      color: "#A6AEB3",
      textTransform: "none",
    },
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.disabled-text": {
            color: "#A6AEB3",
          },
          "&.text-ellipsis": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
          "&.text-ellipsis-twoLines": {
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: "-webkit-box",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: "#D2D6D9",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#BCC2C6",
          "&.Mui-checked": {
            color: "#857BE2",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textAlign: "center",
          borderRadius: "8px",
          letterSpacing: "0.0021em",
          textTransform: "none",
          lineHeight: "20px",
          fontSize: "16px",
          padding: "15px 16px",
          // width: '133px',
          "&.MuiButton-textPrimary": {
            fontWeight: 700,
            textAlign: "center",
            borderRadius: "8px",
            letterSpacing: "0.0021em",
            textTransform: "none",
            lineHeight: "20px",
            fontSize: "16px",
            padding: "15px 22px",
            boxShadow: "0px 2px 2px rgba(1, 164, 154, 0.25)",
            color: "#FFFFFF",
            background: "#00837A",
          },
          "&.MuiButton-textInherit.MuiButton-colorInherit": {
            fontWeight: 700,
            textAlign: "center",
            letterSpacing: "0.0021em",
            lineHeight: "20px",
            fontSize: "16px",
            padding: "15px 22px",
            boxShadow: "none",
            color: "#01736C",
            background: "transparent",
          },
          "&.MuiButton-textInherit.MuiButton-colorInherit.Mui-disabled": {
            fontWeight: 700,
            textAlign: "center",
            letterSpacing: "0.0021em",
            lineHeight: "20px",
            fontSize: "16px",
            padding: "15px 22px",
            boxShadow: "none",
            color: "#79858E",
            background: "transparent",
          },
          "&.MuiButton-textSecondary": {
            fontWeight: 700,
            textAlign: "center",
            borderRadius: "8px",
            letterSpacing: "0.0021em",
            textTransform: "none",
            lineHeight: "20px",
            fontSize: "16px",
            padding: "15px 22px",
            border: "solid 1px #00837A",
            color: "#00837A",
            background: "#FFFFFF",
            "&.Mui-disabled": {
              fontWeight: 700,
              textAlign: "center",
              borderRadius: "8px",
              letterSpacing: "0.0021em",
              textTransform: "none",
              lineHeight: "20px",
              fontSize: "16px",
              padding: "15px 22px",
              border: "solid 1px #79858E !important",
              color: "#79858E !important",
              background: "#FFFFFF !important",
            },
          },

          "&.MuiButton-textSecondary.MuiButton-sizeSmall.MuiButton-textSizeSmall":
          {
            fontWeight: 700,
            textAlign: "center",
            borderRadius: "8px",
            letterSpacing: "0.0021em",
            textTransform: "none",
            lineHeight: "20px",
            fontSize: "16px",
            padding: "10px 22px",
            border: "solid 1px #00837A",
            color: "#00837A",
            background: "#FFFFFF",
          },
          "&.MuiButton-textSecondary.Mui-disabled": {
            border: "none",
            background: "#F2F5F9",
            borderRadius: "8px",
            boxShadow: "none",
          },
          "&.MuiButton-startIcon.MuiButton-iconSizeMedium": {
            "&:hover": {
              color: "#FFFFFF",
              backgroundColor: "#4DC0B8",
              // boxShadow: '0px 4px 10px rgba(1, 115, 108, 0.35)',
            },
            "&:active": {
              color: "#FFFFFF",
              backgroundColor: "#00736B",
              boxShadow: "0px 2px 2px rgba(1, 164, 154, 0.25)",
            },
            "&:disabled": {
              color: "#67737C",
              boxShadow: "0px 2px 2px rgba(121, 133, 142, 0.25)",
              backgroundColor: "#E9EBEC",
            },
          },
          "&:hover": {
            color: "#FFFFFF",
            backgroundColor: "#4DC0B8",
            // boxShadow: '0px 4px 10px rgba(1, 115, 108, 0.35)',
          },
          "&:active": {
            color: "#FFFFFF",
            backgroundColor: "#00736B",
            boxShadow: "0px 2px 2px rgba(1, 164, 154, 0.25)",
          },
          "&:disabled": {
            color: "#67737C",
            boxShadow: "0px 2px 2px rgba(121, 133, 142, 0.25)",
            backgroundColor: "#E9EBEC",
          },

          "&.ml-button": {
            marginLeft: "24px",
          },

          //variants
          "&.large-button": {
            height: "50px",
          },

          "&.medium-button": {
            height: "40px",
          },

          //table buttons
          "&.doc-button": {
            padding: "14px 18px",
            height: "40px",
            "&:hover": {
              color: "#00837A",
              backgroundColor: "#E6F6F5",
              // boxShadow: '0px 4px 10px rgba(1, 115, 108, 0.35)',
            },
            "&:active": {
              color: "#FFFFFF",
              backgroundColor: "#00736B",
              boxShadow: "0px 2px 2px rgba(1, 164, 154, 0.25)",
            },
            "&:disabled": {
              color: "#67737C",
              boxShadow: "0px 2px 2px rgba(121, 133, 142, 0.25)",
              backgroundColor: "#E9EBEC",
            },
          },

          "&.error-table-button": {
            padding: "14px 18px",
            height: "40px",
            "&:hover": {
              color: "#FFFFFF",
              backgroundColor: "#E53935",
              boxShadow: "0px 2px 6px rgba(229, 57, 53, 0.35)",
            },
            "&:active": {
              color: "#FFFFFF",
              backgroundColor: "#AB0005",
              boxShadow: "0px 2px 2px rgba(229, 57, 53, 0.25)",
            },
            "&:disabled": {
              color: "#67737C",
              backgroundColor: "#E9EBEC",
            },
            variants: [],
          },
          MuiGrid: {
            styleOverrides: {
              root: {
                // '& .MuiGrid-root': {
                //   marginTop: '0px'
                // },
                "&.item-grid-1": {
                  width: "314px",
                },
                "&.item-grid-2": {
                  width: "300px",
                },
                "&.item-grid-3": {
                  width: "340px",
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {},
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                "&.select-special": {
                  width: "67px",
                  height: "32px",
                  borderRadius: "8px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#00736B",
                  letterSpacing: "0.0120588em",
                  "& fieldset": {
                    border: "1px solid #00736B",
                    width: "67px",
                  },
                  "& .MuiSelect-icon": {
                    color: "#00736B",
                  },
                },
                "&.border-color": {
                  "& .MuiInputBase-input": {
                    borderColor: "#5D5FEF",
                    borderRadius: "8px",
                  },
                },
              },
            },
          },
          MuiList: {
            styleOverrides: {
              root: {
                padding: 0,
              },
            },
          },
          
          // MuiSvgIcon: {
          //     styleOverrides: {
          //         root: {
          //             "&.icon-pointer": {
          //                 cursor: 'pointer'
          //             }
          //         }
          //     }
          // },
          // MuiTooltip: {
          //     styleOverrides: {
          //         tooltip: {
          //             backgroundColor: '#FFFFFF',
          //             color: 'rgba(0, 0, 0, 0.87)',
          //             border: '1px solid #dadde9',
          //             maxWidth: '576px',
          //             maxHeight: '263px',
          //             borderRadius: '8px',
          //             boxShadow: '0px 6px 20px #B2B9CF'
          //         },
          //         tooltipArrow: {
          //             fontSize: 28,
          //             '& .MuiTooltip-arrow::before': {
          //                 backgroundColor: '#FFFFFF',
          //                 boxShadow: '0px 2px 5px rgba(201, 207, 227, 0.75)',
          //                 // height: '27px',
          //             }
          //         },
          //     },
          // },
          MuiPaper: {
            styleOverrides: {
              root: {
                "&.paper-tables": {
                  width: "100%",
                  border: "1px solid #D2D6D9",
                  borderRadius: "8px",
                  boxShadow: "none",
                  overflow: "hidden",
                },
                "&.paper-tables-2": {
                  width: "100%",
                  boxShadow: "none",
                  overflow: "hidden",
                  minHeight: "200px",
                },
                "&.paper-calendar": {
                  "& .MuiSvgIcon-root": {
                    color: "#01A49A",
                  },
                },
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              root: {
                "&.shadow-menu": {
                  "& .MuiPaper-root": {
                    boxShadow: "0px 2px 10px #C9CFE3",
                  },
                },
              },
            },
          },
          MuiModal: {
            styleOverrides: {
              root: {
                "&.background-modal": {
                  "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                  },
                },
              },
            },
          },
          MuiTableContainer: {
            styleOverrides: {
              root: {
                padding: "18px 3px 0px 5px",
                boxShadow: "none",
                "&.tContainer-padding-0": {
                  padding: "0 0",
                  borderRadius: "0px",
                },
                "::-webkit-scrollbar": {
                  height: "8px",
                },
                "::-webkit-scrollbar-track": {
                  background: "#FFFFFF",
                },
                "::-webkit-scrollbar-thumb": {
                  background: " #4DC0B8",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  background: " #399e97",
                },
              },
            },
          },
          MuiTableBody: {
            styleOverrides: {
              root: {
                "&.pCells-boni": {
                  "&.MuiTableCell-body": {
                    padding: "10px 14px",
                    color: "red",
                  },
                },
              },
            },
          },
          MuiTableRow: {
            styleOverrides: {
              root: {
                "&.MuiTableRow-hover": {
                  "&:hover": {
                    backgroundColor: "#E6F6F5",
                  },
                },
                "&.bg-color-green": {
                  backgroundColor: "#E6F6F5",
                },
              },
            },
          },
          MuiTableHead: {
            styleOverrides: {
              root: {
                "&.color-head-table": {
                  backgroundColor: "#F8F9F9",
                },
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                "&.MuiTableCell-head": {
                  padding: "18px 14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#364855",
                  letterSpacing: "0.0120588em",
                },
                "&.MuiTableCell-body": {
                  padding: "18px 14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#4C4C4C",
                  letterSpacing: "0.0120588em",
                },
                "&.tCell-p": {
                  padding: "10px 14px",
                  textTransform: "capitalize",
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                },
              },
            },
          },
          MuiTablePagination: {
            styleOverrides: {
              root: {
                display: "flex !important",
                justifyContent: "center !important",
                "&.MuiInputBase-input": {
                  border: "1px solid #BCC2C6 !important",
                  borderRadius: "8px !important",
                  padding: "4px 8px 4px 12px !important",
                  width: "22px !important",
                  height: "16px !important",
                  display: "flex !important",
                  flexDirection: "row !important",
                  alignItems: "center !important",
                  "&:focus": {
                    borderRadius: "8px !important",
                    backgroundColor: "white !important",
                  },
                },
              },
            },
          },
          MuiFormControl: {
            styleOverrides: {
              root: {
                "&.full-width": {
                  width: "100%",
                },
              },
            },
          },
          MuiRadio: {
            styleOverrides: {
              root: {
                color: "#BCC2C6",
                "&.Mui-checked": {
                  color: "#857BE2",
                },
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                textTransform: "none",
                fontWeight: 700,
                fontSize: "12px",
                lineHeight: "16px",
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              root: {
                minHeight: "38px",
                "& .MuiButtonBase-root.MuiTab-root": {
                  padding: "8px 12px",
                  color: "#79858E",
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "20px",
                  letterSpacing: "0.0120588em",
                },
                "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                  padding: "8px 12px",
                  color: "#01736C",
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "20px",
                  letterSpacing: "0.0120588em",
                },
                "& .MuiButtonBase-root.Mui-selected": {
                  color: "#FFFFFF",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#F2F5F9",
                  borderBottom: "solid 2px #01A49A",
                },
                "& .MuiTabs-scrollButtons": {
                  marginLeft: "8px",
                },
                "& .MuiTabs-scrollButtons.Mui-disabled": {
                  display: "none",
                },
                "& .MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary": {
                  minHeight: "8px",
                  marginRight: "8px",
                  border: "1px solid #5D5FEF",
                  color: "#5D5FEF",
                  borderRadius: "100px",
                  padding: "8px 12px",
                  fontFamily: "Interstate",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "12px",
                  lineHeight: "16px",
                  backgroundColor: "#FFFFFF",
                },
                "& .MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary.Mui-selected":
                {
                  backgroundColor: "#5D5FEF",
                  color: "#FFFFFF",
                },
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              sizeSmall: {
                fontSize: "10px",
              },
            },
          },
          MuiAlert: {
            styleOverrides: {
              standardInfo: {
                fontFamily: "Rubik",
                fontWeight: 500,
                fontSize: "12px",
                fontStyle: "normal",
                borderRadius: "8px",
                boxShadow: "none",
              },
              standardSuccess: {
                fontFamily: "Rubik",
                fontSize: "12px",
                border: "1px solid #7CB342",
                backgroundColor: "#F1F8E9",
                fontWeight: 500,
                fontStyle: "normal",
                color: "#203442",
                borderRadius: "8px",
                boxShadow: "none",
                "& .MuiAler-icon": {
                  fontSize: "10px",
                },
              },
              standardError: {
                fontFamily: "Rubik",
                fontSize: "12px",
                border: "1px solid #E57373",
                backgroundColor: "#FFEBEE",
                fontWeight: 500,
                fontStyle: "normal",
                borderRadius: "8px",
                boxShadow: "none",
                color: "#203442",
              },
              standardWarning: {
                fontFamily: "Rubik",
                fontSize: "12px",
                backgroundColor: "#F1F8E9",
                fontWeight: 500,
                fontStyle: "normal",
                borderRadius: "8px",
                boxShadow: "none",
                color: "#203442",
              },
            },
          },
          MuiSnackbar: {
            styleOverrides: {
              root: {
                // bottom: "14% !important",
                top: "20% !important",
              },
            },
          },
        },
      },
    },
  },
});

export default theme;
