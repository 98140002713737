import * as React from 'react';
import { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Paper, Typography } from '@mui/material';
import Breadcrum from 'src/components/breadcrum/Breadcrum';
import PaymentsTable from './paymentsTable';
import { getClosingIdMembers } from 'src/services/backoffice/closingReimbursementsServices';
import { Data } from 'src/interfaces/ReimbursementInterface/Data.interface';
import { ClosingReimbursements } from 'src/interfaces/ReimbursementInterface/ClosingReimbursement.interface';
import { GroupedData } from 'src/interfaces/ReimbursementInterface/GroupedData.interface';
import ReimbursementsTable from '../Closing/ClosingList/reimbursementsTable';
import ClosingDetailsTable from './ClosingDetailsTable/closingDetailTable';
import DashboardReimbursement from '../Closing/ClosingDashboard/dashboard';
import { dataDashboardInterface } from '../Closing/reimbursementContext';


interface PaymentsTableProps {
    data: ClosingReimbursements[]
    setData: () => void,
    loadingClosing: boolean
}



const PaymentMenu: React.FC<PaymentsTableProps> = ({data, setData, loadingClosing}) => {
    const [value, setValue] = useState('1');
    const [id, setId] = useState<string>("");
    const [dataResponse, setDataResponse] = useState<GroupedData[]>([])
    
    const [costoTotalAcumulado, setCostoTotalAcumulado] = useState(0);
    const [loading, setLoading] = useState(true);
    const [beneficioTotalAcumulado, setBeneficioTotalAcumulado] = useState(0);
    const [cantidadPrestaciones, setCantidadPrestaciones] = useState(0);
    const [dataReimbursements, setDataReimbursements] = useState<GroupedData[]>([]);
    const [tabDetails, setTabDetails] = useState(true)
    const [perfectedBy, setPerfectedBy] = useState(false)

    const getData = async (id: string) => {
        try {
            const response = await getClosingIdMembers(id);
            setDataReimbursements(groupReimbursementsByFolio(response));
        } catch (err) {
            setValue("1")
            setTabDetails(true)
            return err
        }
        setLoading(false)
    }

    const reset = (id: string) => {
        getData(id)
    }

    const dataDashboard: dataDashboardInterface = {
        cantidadPrestaciones: cantidadPrestaciones,
        beneficioAcumulado: beneficioTotalAcumulado,
        costoTotalAcumulado: costoTotalAcumulado,
        cantidadReembolsos: dataReimbursements.length,
        dataReimbursements: dataReimbursements
    }

    const groupReimbursementsByFolio = (data: Data[]): GroupedData[] => {

        const { groupedData, sumTotals } = data.reduce((acc, currentCase) => {
            const { settlement_folio, per_benefit_cost, benefit_quantity, base_bonus_amount, additional_bonus_amount, reimbursement_status, request_date, comptroller_email } = currentCase;

            if (!acc.groupedData[settlement_folio]) {
                acc.groupedData[settlement_folio] = {
                    cases: [],
                    costo_total: 0,
                    beneficio_total: 0,
                    estado: undefined,
                    request_date: undefined,
                    comptroller_email: undefined,
                };
            }

            acc.groupedData[settlement_folio].cases.push(currentCase);
            acc.groupedData[settlement_folio].costo_total += per_benefit_cost * benefit_quantity;;
            acc.groupedData[settlement_folio].beneficio_total += base_bonus_amount + additional_bonus_amount;
            acc.groupedData[settlement_folio].estado = reimbursement_status;
            acc.groupedData[settlement_folio].request_date = request_date;
            acc.groupedData[settlement_folio].comptroller_email = comptroller_email;

            acc.sumTotals.costo_total_acumulado += per_benefit_cost * benefit_quantity;
            acc.sumTotals.beneficio_total_acumulado += base_bonus_amount + additional_bonus_amount;

            return acc;
        }, {
            groupedData: {} as { [key: string]: { cases: Data[], costo_total: number, beneficio_total: number, estado?: string, request_date?: string, comptroller_email?: string } },
            sumTotals: { costo_total_acumulado: 0, beneficio_total_acumulado: 0, cantidad_prestaciones: 0 }
        });

        const result = Object.keys(groupedData).map(settlement_folio => ({
            settlement_folio,
            count: groupedData[settlement_folio].cases.length,
            cases: groupedData[settlement_folio].cases,
            costo_total: groupedData[settlement_folio].costo_total,
            beneficio_total: groupedData[settlement_folio].beneficio_total,
            estado: groupedData[settlement_folio].estado,
            request_date: groupedData[settlement_folio].request_date,
            comptroller_email: groupedData[settlement_folio].comptroller_email,
        }));

        setCostoTotalAcumulado(sumTotals.costo_total_acumulado);
        setBeneficioTotalAcumulado(sumTotals.beneficio_total_acumulado);
        setCantidadPrestaciones(data.length);

        return result;
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleChevronClick = async (id: string, perfected_by?: string ) => {
        setDataReimbursements([])
        setLoading(true)
        setId(id);
        setValue("2");
        setTabDetails(false)

        if (perfected_by) {
            setPerfectedBy(true)
        }

        try {
            getData(id);
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <>
            
            <Box>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Listado de cierres" value="1" />
                            <Tab label="Detalle reembolsos" value="2" disabled={tabDetails}/>
                            <Tab label="Resumen" value="3" disabled={tabDetails}/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        {/* <Box m={4} lineHeight={1.5} fontFamily="Inter">
                            <Paper elevation={2}> */}
                                <PaymentsTable onChevronClick={handleChevronClick} dataClosing={data} setDataClosing={setData} loading={loadingClosing} />
                                
                            {/* </Paper>
                        </Box> */}
                    </TabPanel>
                    <TabPanel value="2">
                        <ClosingDetailsTable cases={dataReimbursements} loading={loading} reset={ () => getData(id) } perfected_by={perfectedBy} />
                    </TabPanel>
                    <TabPanel value="3">
                        <DashboardReimbursement loading={loading} data={dataDashboard}></DashboardReimbursement>
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    );
}

export default PaymentMenu;