import { getFreshAccessToken, getUserData } from "src/utils/msal";
import { allowOperationIfAuthorizedRole } from "src/helpers/roleManager";


async function createReimbursements(ids) {
    const user = await getUserData()
    // await allowOperationIfAuthorizedRole(new Set(["ESENCIAL-BO_ISA_EJECUTIVO", "ESENCIAL-BO_ISA_CIERRE"]));

    try {
        const freshToken = await getFreshAccessToken();

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${freshToken}`);
        const raw = JSON.stringify({
            "target": "REIMBURSEMENT",
            "created_by": user.email,
            "member_ids": ids
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        const baseUrl = `${process.env.REACT_APP_API_URL}/api/v2/closings`;

        const response = await fetch(baseUrl, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return { success: true, data: result };
    } catch (error) {
        return { success: false, error: error.message };
    }
}

export default createReimbursements;





