import React, { useEffect, useState } from "react";
import {
    Table, TableBody, TableContainer, TableHead, TableRow,
    TablePagination, Paper, Checkbox, Toolbar, IconButton, Tooltip,
    Typography, Box,
    Fade,
    CircularProgress,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import SendIcon from '@mui/icons-material/Send';
import "../Closing/ClosingList/reimbursementsTable.css"
import ConfirmationDialog from "../Closing/ClosingList/alertDialog/confirmationDialog";
import { patchClosingReimbursements, deleteClosingId, getClosingReport } from "src/services/backoffice/closingReimbursementsServices";
import { ClosingReimbursements } from "src/interfaces/ReimbursementInterface/ClosingReimbursement.interface";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import pako from 'pako';
import { ClosingBlueprintDocument, ClosingReportInterface, ClosingDocument, ClosingDocumentEntry } from "src/interfaces/Closing/ClosingReport.interface";
import dayjs, { Dayjs } from 'dayjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { invalidOperationError } from "src/exceptions/exceptions";

const zip = (a: Array<any>, b: Array<any>) => a.map((k, i) => [k, b[i]]);

type OptionalToNull<T> = {
    [K in keyof T]: T[K] extends undefined ? null : T[K] | null;
};

function createObjectWithNulls2<T>(obj: T): OptionalToNull<T> {
    const result: any = {};
    for (const key in obj) {
        if (obj[key] !== undefined) {
            result[key] = obj[key];
        } else {
            result[key] = null;
        }
    }
    return result;
}

function createObjectWithNulls(obj: Partial<ClosingDocumentEntry>) {
    const result: Partial<ClosingDocumentEntry> = {};
   
    const keys: Array<keyof ClosingDocumentEntry> = [
        "Clave de control", "Fecha documento", "Fecha Contable", "Numero referencia", "Texto cabecera",
        "Clase de documento", "Periodo", "Sociedad FI", "Moneda", "Tipo de cambio", "Ledger",
        "Nº cuenta a contabilizar", "indicador iva", "Sociedad GL", "Código de cliente",
        "Código de proveedor", "Indicador CME", "Importe Docto Documento", "Indicador debe Haber",
        "Fecha Base", "Condición de pago", "Fecha Valor", "Fecha de vencimiento",
        "Centro de costo", "Centro de beneficio", "Asignación", "Texto posición",
        "Clave referencia 1", "Clave referencia 2", "Clave referencia 3", "Estatus",
        "Clave Banco", "País Banco", "Cuenta Bancaria", "Nombre Completo", "Mail",
        "Fecha de Pago Máxima", "Fecha de Pago Efectiva", "Estado de Pago", "Motivo Pago"
    ];

    for (const key of keys) {
        result[key] = obj[key] !== undefined ? obj[key] : null as any;
    }
    return result;
}




function chunkArray<T>(array: T[], chunkSize: number): T[][] {
    const result: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
    }
    return result;
}

const combinarListas = (a: Array<any>, b: Array<any>) => {
    const resultado: Array<any> = [];
    for (let i = 0; i < a.length; i++) {
        resultado.push(a[i], b[i]);
    }
    return resultado;
};

const generateDownloadablePaymentDocument = (data: ClosingDocument) => {
    const processedData = data.entries.map(entry => createObjectWithNulls(entry))

    const ws = XLSX.utils.json_to_sheet(data.entries.map(entry => createObjectWithNulls(entry)));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, data.name);
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: 'application/octet-stream' });
}

const breakDownloadablePaymentDocumentHack = (document: ClosingDocument, bins: number): Array<ClosingDocument> => {
    const splittedClosingDocument = chunkArray(document.entries, bins)

    return splittedClosingDocument.map(chunk => {
        return {
            name: document.name,
            entries: chunk.map((entry, i) => {
                const n = (i % 2 === 0) ? Number(i / 2) + 1 : Number((i - 1) / 2) + 1
                entry["Clave de control"] = n
                return entry
            })
        }
    })
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        borderTop: "1px solid #d3d3d3",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface PaymentsTableProps {
    onChevronClick: (id: string, perfected_by?: string) => void;
    dataClosing: ClosingReimbursements[];
    setDataClosing: () => void,
    loading: boolean,
}

const PaymentsTable: React.FC<PaymentsTableProps> = ({ onChevronClick, dataClosing, setDataClosing, loading }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [selected, setSelected] = useState<string[]>([]);
    const [idDelete, setIdDelete] = useState("")
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        setSelected([])
    }, [dataClosing]);

    const descomprimirBase64GZIP = (base64: string): any => {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const descomprimido = pako.inflate(bytes, { to: 'string' });
        return JSON.parse(descomprimido);
    };

    function initializeUndefined<T>(keys: (keyof T)[]): { [K in keyof T]: undefined } {
        const result: any = {};
        keys.forEach(key => {
            result[key] = undefined;
        });
        return result;
    }

    

    const generateClosingDocuments = (blueprint: ClosingBlueprintDocument, perfectionDate: Dayjs): ClosingDocument => {
        // NOTE: Columnas en blanco corregir
        const surplusBenefitCode = "5000006" // NOTE: Consultar con Pablo como manejar casos de excedentes

        const debe: Array<ClosingDocumentEntry> = blueprint.registries.map((r: ClosingReportInterface, i: number) => {
            const entry: ClosingDocumentEntry = {
                "Clave de control": i + 1,
                "Fecha documento": perfectionDate.format("YYYYMMDD").toString(),
                "Fecha Contable": perfectionDate.format("YYYYMMDD").toString(),
                "Numero referencia": r.settlement_folio,
                "Texto cabecera": `${blueprint.master_document_name}${perfectionDate.format("YYYYMM").toString()}`,
                "Clase de documento": "KY",
                "Periodo": Number(perfectionDate.format("M")),
                "Sociedad FI": "CL07",
                "Moneda": "CLP",
                "Nº cuenta a contabilizar": blueprint.master_account_number,
                "Importe Docto Documento": r.total_bonus,
                "Indicador debe Haber": "D",
                "Centro de costo": blueprint.cost_center,
                "Asignación": r.settlement_folio,
                "Texto posición": `${blueprint.master_document_name}${perfectionDate.format("YYYYMM").toString()}`,
            }
            return entry as ClosingDocumentEntry
        })

        const haber: Array<ClosingDocumentEntry> = blueprint.registries.map((r: ClosingReportInterface, i: number) => {
            const entry: ClosingDocumentEntry = {
                "Clave de control": i + 1,
                "Fecha documento": perfectionDate.format("YYYYMMDD").toString(),
                "Fecha Contable": perfectionDate.format("YYYYMMDD").toString(),
                "Numero referencia": r.settlement_folio,
                "Texto cabecera": `${blueprint.master_document_name}${perfectionDate.format("YYYYMM").toString()}`,
                "Clase de documento": "KY",
                "Periodo": Number(perfectionDate.format("M")),
                "Sociedad FI": "CL07",
                "Moneda": "CLP",
                "Código de cliente": r.affiliate_rut,
                "Importe Docto Documento": r.total_bonus,
                "Indicador debe Haber": "H",
                "Asignación": r.settlement_folio,
                "Texto posición": `${blueprint.master_document_name}${perfectionDate.format("YYYYMM").toString()}`,
                "Clave Banco": r.bank_sbif,
                "País Banco": "CL",
                "Cuenta Bancaria": r.bank_account_number,
                "Nombre Completo": r.affiliate_name,
                "Fecha de Pago Máxima": perfectionDate.add(3, 'day').format("YYYYMMDD").toString(), // NOTE: Debe usar funcion para encontrar siguiente dia habil de la fecha del documento
            }

            return entry

        })

        return {
            name: blueprint.master_document_name.toLowerCase(),
            entries: combinarListas(debe, haber)
        }

    }

    const handleDownload = async (id: string, documentLength: number, perfectionDate?: string) => {
        if (perfectionDate === undefined) {
            return invalidOperationError()
        }

        const b64 = await getClosingReport(id);
        const data: Array<ClosingBlueprintDocument> = descomprimirBase64GZIP(b64);
        const now = dayjs();
        const zip = new JSZip();
        const documents = data.map(i => generateClosingDocuments(i, dayjs(perfectionDate)));

        documents.forEach(document => {
            const filename = `${now.format("YYYYMMDD-HHmmss")}-pago-${document.name}`;
            if (document.entries.length === 0) {
                return;
            } else if (document.entries.length < documentLength) {
                const content = generateDownloadablePaymentDocument(document);
                zip.file(`${filename}.xlsx`, content);
            } else {
                const splittedDocument = breakDownloadablePaymentDocumentHack(document, documentLength - 1);
                splittedDocument.forEach((chunk, i) => {
                    const content = generateDownloadablePaymentDocument(chunk);
                    zip.file(`${filename}-${(i + 1).toString().padStart(3, "0")}.xlsx`, content);
                });
            }
        });

        zip.generateAsync({ type: 'blob' }).then(content => {
            saveAs(content, `${now.format("YYYYMMDD-HHmmss")}-pagos.zip`);
        });

    };



    const handleOpenDialog = () => {
        setDialogOpen(true)
    }

    const handleCloseDialog = () => {
        setDialogOpen(false)
    }

    const deleteId = async (id: string) => {
        try {
            await deleteClosingId(id)
        } catch (e) {
            console.log(e)
        }
    }

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = dataClosing.map((group) => group.id);
            setSelected(newSelecteds);
        } else {
            setSelected([]);
        }
    };

    const handleCheckboxClick = (event: React.MouseEvent, id: string) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const columns = [
        {
            id: "select", label: ""
        },
        { id: "id", label: "id" },
        { id: "created_at", label: "fecha cierre" },
        { id: "created_by", label: "creado por" },
        { id: "perfected_at", label: "fecha de pago" },
        { id: "perfected_by", label: "pagado por" },
        { id: "actions", label: "" },
    ];



    return (
        <>
            <Box width="100%" >
                <Paper>
                    <EnhancedTableToolbar numSelected={selected.length} ids={selected} setData={setDataClosing} />

                    {loading ? (

                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box height={400} display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
                                <Fade
                                    in={loading}
                                    style={{
                                        transitionDelay: loading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <p>Cargando...</p>
                            </Box>
                        </Box>


                    ) : (
                        <>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <StyledTableCell
                                                    key={column.id}
                                                    sx={{ height: 42.5 }}
                                                >
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataClosing.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).reverse().map((group, index) => {
                                            const isItemSelected = isSelected(group.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (

                                                <React.Fragment key={group.id}>
                                                    <StyledTableRow
                                                        sx={{ height: 42.5 }}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        selected={isItemSelected}
                                                        tabIndex={-1}
                                                    >
                                                        <StyledTableCell>
                                                            {!group.perfected_by ? (
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                    onClick={(event) => handleCheckboxClick(event, group.id)}
                                                                />
                                                            ) : null}

                                                        </StyledTableCell>

                                                        {/* <StyledTableCell>{page * rowsPerPage + index + 1}</StyledTableCell> */}
                                                        <StyledTableCell>{group.id}</StyledTableCell>
                                                        <StyledTableCell>{dayjs(group.created_at).format("DD/MM/YYYY HH:mm").toString()}</StyledTableCell>
                                                        <StyledTableCell>{group.created_by}</StyledTableCell>
                                                        <StyledTableCell>{group.perfected_at ? dayjs(group.perfected_at).format("DD/MM/YYYY HH:mm").toString() : null}</StyledTableCell>
                                                        <StyledTableCell>{group.perfected_by}</StyledTableCell>
                                                        <StyledTableCell>
                                                            {group.perfected_by ? (
                                                                <>
                                                                    <Tooltip title="Descargar">
                                                                        <IconButton
                                                                            aria-label="expand row"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                handleDownload(group.id, 999, group.perfected_at)
                                                                            }}
                                                                        >
                                                                            <FileDownloadIcon />
                                                                        </IconButton>
                                                                    </Tooltip>


                                                                    <Tooltip title="Ver prestaciones">
                                                                        <IconButton
                                                                            aria-label="expand row"
                                                                            size="small"
                                                                            onClick={() =>
                                                                                onChevronClick(group.id, group.perfected_by)
                                                                            }
                                                                        >
                                                                            <ChevronRightIcon />
                                                                        </IconButton>
                                                                    </Tooltip>


                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Tooltip title="Eliminar">
                                                                        <IconButton
                                                                            aria-label="expand row"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                handleOpenDialog()
                                                                                setIdDelete(group.id)
                                                                            }}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Ver prestaciones">
                                                                        <IconButton
                                                                            aria-label="expand row"
                                                                            size="small"
                                                                            onClick={() =>
                                                                                onChevronClick(group.id, group.perfected_by)
                                                                            }
                                                                        >
                                                                            <ChevronRightIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            )}
                                                        </StyledTableCell>
                                                    </StyledTableRow>


                                                </React.Fragment>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[20, 50, 100]}
                                component="div"
                                count={dataClosing.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(event, newPage) => setPage(newPage)}
                                onRowsPerPageChange={(event) => {
                                    setRowsPerPage(parseInt(event.target.value, 10))
                                    setPage(0)
                                }}
                                labelRowsPerPage="Filas por página"
                                labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`; }}
                            />
                            <ConfirmationDialog passKey={"eliminar"} callback={() => {
                                return deleteClosingId(idDelete)
                            }
                            } open={dialogOpen} onClose={handleCloseDialog} reset={setDataClosing}>
                            </ConfirmationDialog>
                        </>
                    )}
                </Paper>
            </Box>
        </>
    );
};

interface EnhancedTableToolbarProps {
    numSelected: number;
    ids: string[];
    setData: () => void
}



const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {

    const { numSelected, ids, setData } = props;
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = () => {
        setDialogOpen(true)
    }

    const handleCloseDialog = () => {
        setDialogOpen(false)
    }

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        theme.palette.action.selected,
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} seleccionados
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Listado de cierres para pagos
                </Typography>
            )}
            {numSelected > 0 ? (
                <>

                    <Tooltip title="Pagar">
                        <IconButton onClick={handleOpenDialog}>
                            <SendIcon />
                        </IconButton>
                    </Tooltip>

                    <ConfirmationDialog passKey={"pagar"} callback={() => patchClosingReimbursements(ids)} open={dialogOpen} onClose={handleCloseDialog} reset={setData}>
                    </ConfirmationDialog>
                </>
            ) : (
                null
            )}
        </Toolbar>
    );
};

export default PaymentsTable;