import { Outlet } from "react-router-dom"
import AppBarAlemana from "src/components/header/AppBarAlemana";
import "./Layout.css"

const BaseLayout = () => {
    return (
        <div className="App">
            <AppBarAlemana />
            <Outlet />
        </div>
    )
}

export default BaseLayout;