import { useState, useRef, useEffect } from "react";
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Collapse, FormControlLabel, FormGroup, Grid, IconButton, IconButtonProps, Paper, Stack, TextField, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { getCandidates } from "src/services/backoffice/reimbursementsServices";
import ReimbursementContext, { useSum } from "../Reimbursement/Closing/reimbursementContext";
import ClosingMenu from "../Reimbursement/Closing/ClosingMenu";
import { Link } from "react-router-dom"
import Breadcrum from "src/components/breadcrum/Breadcrum";
import { useAppContext } from "../Reimbursement/appContext";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


const ReimbursementsClosing = () => {

    const [checked, setChecked] = useState(false);
    const [value, setValue] = useState<Dayjs | null>(null);
    const { getData } = useSum()
    const { showView, setShowView, resetView } = useAppContext()

    useEffect(() => {
        resetView()
    }, [])

    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue);
    };

    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        event.target.checked ? setValue(value) : setValue(null);
    };

    const handleChangeView = (showViewParam: boolean) => {
        setShowView(showViewParam)
    };

    return (
        <>
            <Box m={4}>
                <Typography variant="h1" pt="24px" mb="16px">
                    Reembolsos
                </Typography>
                {/* <Breadcrum back1="Inicio" link1="/" now="Cierre" /> */}
            </Box>

            {!showView && (
                <>
                    <Box m={4} lineHeight={1.5} fontFamily="Inter" my={22}>
                        <Paper elevation={2} >
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Stack gap={2} m={3}>
                                    {/* <Typography>Por favor, si desea generar un cierre hasta un día determinado, seleccione "Cerrar antes de..." e ingrese el día correspondiente.</Typography> */}
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox
                                            checked={checked}
                                            onChange={handleChangeCheck}
                                        />} label="Cerrar antes de..." />
                                    </FormGroup>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            inputFormat="DD/MM/YYYY"
                                            value={value}
                                            onChange={handleChange}
                                            renderInput={(params) => <TextField {...params} />}
                                            disabled={!checked}
                                            disableFuture
                                        />
                                    </LocalizationProvider>
                                    {/* <Button onClick={getCandidatesData}>Genera cierre</Button> */}
                                    <Button
                                        fullWidth
                                        onClick={ async ()  => {
                                            getData(value?.format("YYYY-MM-DD").toString());
                                            handleChangeView(true);
                                        }} >Generar cierre</Button>
                                </Stack>
                            </Box>
                        </Paper>
                    </Box>
                </>

            )}

            {showView && (
                <ClosingMenu></ClosingMenu>
            )}

        </>
    );
};

export default ReimbursementsClosing;
