import React from 'react';
import { Box, Button, Card, CardContent, CircularProgress, Fade, Grid, Paper, Typography } from '@mui/material';
import { dataDashboardInterface, useSum } from '../reimbursementContext';
import IndicatorsMonth from '../ClosingDashboard/Indicators/indicatorsMonth'
import IndicatorsPeriod from '../ClosingDashboard/Indicators/indicatorsPeriod'

const ShowNumber = ({ num, info, isCurrency }: { num: number, info: string, isCurrency?: boolean }) => {

    const fmtNum: string = isCurrency ? `$${new Intl.NumberFormat().format(num)}` : num.toString()

    return (
        <Paper
            elevation={2}
            sx={{
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent: 'center',
                fontFamily: 'Inter'
            }}>
            <h1 style={{ fontSize: '3rem' }} >{fmtNum}</h1>
            <Typography variant="h6">{info}</Typography>
        </Paper>
    )
}


const DashboardReimbursement = ({ loading, data}: { loading: boolean, data: dataDashboardInterface }) => {

    return (
        <>
            {loading ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box height={400} display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
                        <Fade
                            in={loading}
                            style={{
                                transitionDelay: loading ? '800ms' : '0ms',
                            }}
                            unmountOnExit
                        >
                            <CircularProgress />
                        </Fade>
                        <p>Cargando...</p>
                    </Box>
                </Box>
            ) : (
                <Grid container spacing={2}>

                    <Grid item xs={3}>
                        <ShowNumber num={data.cantidadReembolsos} info="Cantidad de reembolsos" />
                    </Grid>

                    <Grid item xs={3}>
                        <ShowNumber num={data.cantidadPrestaciones} info="Cantidad de prestaciones" />
                    </Grid>

                    <Grid item xs={3}>
                        <ShowNumber num={data.costoTotalAcumulado} info="Costo acumulado" isCurrency={true} />
                    </Grid>

                    <Grid item xs={3}>
                        <ShowNumber num={data.beneficioAcumulado} info="Bonificación acumulada" isCurrency={true} />
                    </Grid>

                    <Grid item xs={6}>
                        <Paper
                            elevation={2}
                            sx={{
                                height: '500px',
                                padding: 4,
                                textAlign: 'center',
                            }}>
                            <IndicatorsMonth data={data.dataReimbursements} />
                        </Paper>
                    </Grid>

                    <Grid item xs={6}>
                        <Paper
                            elevation={2}
                            sx={{
                                height: '500px',
                                padding: 4,
                                textAlign: 'center',
                            }}>
                            <IndicatorsMonth data={data.dataReimbursements}/>
                        </Paper>
                    </Grid>

                </Grid>
            )}

        </>
    )
}

export default DashboardReimbursement;