import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "src/services/auth/authConfig";
import { getUserName, useVerifyRoles, getUserImage  } from "src/utils/msal";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { AppBar, Stack } from "@mui/material";
import Logo from "../../assets/icons/logo.svg";
// import Avatar from "../../assets/icons/avatar.svg";
import icon_avatar from "../../assets/icons/icon_avatar.svg";
// import LogoutIcon from "@mui/icons-material/Logout";
import {
  divApp,
  toolbar,
} from "../drawer/headerStyles";
import icon_notify from "../../assets/icons/icon_notify.svg";
import React, { useState, useEffect } from 'react';


const AppBarAlemana = () => {
  const { instance } = useMsal() || {};
  const isAuthenticated = useIsAuthenticated();
  const isAuthorized = useVerifyRoles();

  const logoutService = () => {

    const handleLogout = () => {
      instance.logout();
    };
  
    window.location.href = "/";
    sessionStorage.clear();
  };

  const loginService = (loginType: string) => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  
  const [userImage, setUserImage] = useState<string | null>(null);
  
  // useEffect(() => {
  //   const fetchUserImage = async () => {
  //     const image = await getUserImage();
  //     console.log(image);
  //     setUserImage(image);
  //   };

  //   fetchUserImage();
  // }, []);

  return (
    <div>
      <Box mb="0px" sx={{ boxShadow: "none", flexGrow: 1 }}>
        <AppBar position="fixed" sx={divApp}>
          <Toolbar sx={toolbar}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img src={Logo} alt="" className='logo' />
            </Typography>
            <Stack
              direction="row"
              spacing={3}

            >
              {isAuthenticated && (
                <>
                  <img src={icon_avatar} alt="avatar" className="icon_avatar" />
                  <Stack direction="column" alignSelf="center">
                    <Typography className="name">{getUserName()}</Typography>
                    {/* <Typography className="job">
                      {isAuthorized?.intersection[0]}
                    </Typography> */}
                  </Stack>
                  <Box width="1px">
                  {userImage ?( 
                    <img src={userImage} alt="" className="usuario" />):(<div></div>)
                  }
                    <Divider orientation="vertical" sx={{ borderColor: "rgba(255, 255, 255, 0.6)" }} />
                  </Box>
                  {/* <img src={icon_notify} alt="" className="icon_notify" />
                  <Box width="1px">
                    <Divider orientation="vertical" sx={{ borderColor: "rgba(255, 255, 255, 0.6)" }} />
                  </Box> */}
                </>
              )}
              {isAuthenticated ? (
                <Typography
                  className="cerrarsesion" onClick={()=>logoutService()}>
                  Cerrar sesión
                  <svg
                    width="32"
                    height="34"
                    viewBox="0 0 32 20"
                    fill=""
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ paddingTop: '-17px', marginBottom: '-5px' }}
                  >
                    <path
                      d="M5.16541 6.66663H12.0387C12.3824 6.66663 12.6636 6.94781 12.6636 7.29147V7.70804C12.6636 8.0517 12.3824 8.33289 12.0387 8.33289H5.16541C4.70718 8.33289 4.33228 8.70779 4.33228 9.16602V24.1624C4.33228 24.6206 4.70718 24.9955 5.16541 24.9955H12.0387C12.3824 24.9955 12.6636 25.2767 12.6636 25.6203V26.0369C12.6636 26.3806 12.3824 26.6617 12.0387 26.6617H5.16541C3.78553 26.6617 2.66602 25.5422 2.66602 24.1624V9.16602C2.66602 7.78614 3.78553 6.66663 5.16541 6.66663ZM19.6931 7.682L19.3234 8.0517C19.0787 8.29644 19.0787 8.69217 19.3234 8.93691L26.1967 15.779H11.6222C11.2785 15.779 10.9973 16.0602 10.9973 16.4038V16.9245C10.9973 17.2682 11.2785 17.5494 11.6222 17.5494H26.1967L19.3286 24.3915C19.0839 24.6362 19.0839 25.0319 19.3286 25.2767L19.6983 25.6464C19.943 25.8911 20.3388 25.8911 20.5835 25.6464L29.1491 17.1068C29.3939 16.8621 29.3939 16.4663 29.1491 16.2216L20.5783 7.682C20.3336 7.43727 19.9378 7.43727 19.6931 7.682Z"
                      fill=""
                    />
                  </svg>
                </Typography>
              ) : (
                <Typography
                  color="secondary"
                  className="iniciarsesion"
                  onClick={() => loginService("popup")}
                >
                  Iniciar sesión {"  "}
                </Typography>
              )}
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};
export default AppBarAlemana;
