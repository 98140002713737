const generateAppError = (alertMessage: string, errorMessage: string, service: string) : Error => {
    alert(alertMessage)
    console.error(`${errorMessage} [${service}]`)
    return new Error(`${errorMessage} [${service}]`)
}

export const generalServiceError = (service?: string) : never => {
    throw generateAppError("Error del servicio; no es posible completar esta acción.", "General service error", service || "Backoffice")
}

export const undefinedRoleError = (service?: string) : never => {
    throw generateAppError("Usuario no cuenta con roles definidos para completar esta acción.", "User has no roles", service || "Backoffice")
}

export const unauthorizedRoleError = (service?: string) : never => {
    throw generateAppError("Rol de usuario no tiene permiso para completar esta acción.", "User has no authorized role", service || "Backoffice")
}

export const invalidOperationError = (service?: string) : never => {
    throw generateAppError("Operación inválida.", "Invalid operation; one or more arguments do not comply to spec", service || "Backoffice")
}