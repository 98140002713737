import { useState, useRef, useEffect } from "react";
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Collapse, FormControlLabel, FormGroup, Grid, IconButton, IconButtonProps, Paper, Stack, TextField, Typography } from "@mui/material";
import Breadcrum from "src/components/breadcrum/Breadcrum";
import PaymentsTable from "../Reimbursement/Payment/paymentsTable";
import PaymentMenu from "../Reimbursement/Payment/PaymentMenu";
import { getClosingReimbursements } from "src/services/backoffice/closingReimbursementsServices";
import { ClosingReimbursements } from "src/interfaces/ReimbursementInterface/ClosingReimbursement.interface";
import { useAppContext } from "../Reimbursement/appContext";




const PaymentsClosing = () => {
    const [view, setView] = useState(false);
    const [dataClosing, setClosing] = useState<ClosingReimbursements[]>([]);
    const [loading, setLoading] = useState(true)
    const { showView, setShowView, resetView } = useAppContext()



    const handleChangeView = (showView: boolean) => {
        setView(showView);
        setShowView(showView)
    };

    const resetData = async () => {
        await getDataClosing()
    };

    useEffect(() => {
        
        if(!showView){
            setLoading(true)
            setClosing([])
        }
    }, [showView]);



    const getDataClosing = async () => {
        try {
            const fetchData = await getClosingReimbursements();
            setClosing(fetchData)
        } catch (err) {
            handleChangeView(false);
            return err
        }
        setLoading(false)
    }

    return (
        <>

            <Box m={4}>
                <Typography variant="h1" pt="24px" mb="16px">
                    Reembolsos
                </Typography>
                {/* <Breadcrum back1="Inicio" link1="/" now="Pagos" /> */}
            </Box>

            {!showView && (
                <>

                    <Box m={4} lineHeight={1.5} fontFamily="Inter" my={22}>
                        <Paper elevation={2} >
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Stack gap={2} m={3}>
                                    <Button
                                        fullWidth
                                        onClick={async () => {
                                            handleChangeView(true);
                                            await getDataClosing()
                                        }} >Visualizar cierres</Button>

                                    <Button
                                        fullWidth
                                        onClick={async () => {
                                        }} >Visualizar cierre mensual</Button>
                                </Stack>
                            </Box>
                        </Paper>
                    </Box>
                </>

            )}

            {showView && (

                <PaymentMenu data={dataClosing} setData={resetData} loadingClosing={loading}></PaymentMenu>

            )}

        </>
    );
};

export default PaymentsClosing;