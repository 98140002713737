import { PieChart } from '@mui/x-charts/PieChart';
import { useSum } from "../../reimbursementContext";
import { Box, Stack, Typography } from '@mui/material';
import { GroupedData } from 'src/interfaces/ReimbursementInterface/GroupedData.interface';


interface chartDataPoint {
  id: number,
  value: number,
  label: string
}


const groupReimbursementsByCost = (data: any[]) => {
  const buckets: Array<chartDataPoint> = [
    { id: 0, value: 0, label: "< $10.000" },
    { id: 1, value: 0, label: "$10.000 a $100.000" },
    { id: 2, value: 0, label: "$100.000 a $500.000" },
    { id: 3, value: 0, label: "> $500.000" },
  ]

  data.forEach((costo) => {
    if (costo.costo_total < 10000) {
      buckets[0]["value"]++;
    } else if (costo.costo_total < 100000) {
      buckets[1]["value"]++;
    } else if (costo.costo_total < 500000) {
      buckets[2]["value"]++;
    } else {
      buckets[3]["value"]++;
    }
  });

  return buckets;
};


const IndicatorsMonth = ({data} : {data: GroupedData[]}) => {

  return (
    <>
        <Typography variant='h1'>Costo total tramos</Typography>

        <PieChart
          margin={{ top: 40, bottom: 70, left: 30, right: 30 }}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
            },
          }}
          series={[
            {
              data: groupReimbursementsByCost(data),
            },
          ]}

        />
    </>
  );
};

export default IndicatorsMonth;
