import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { MsalProvider } from "@azure/msal-react";
import { getAccount } from "./utils/msal";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/themes/themeConfig";
import { AppProvider } from "src/pages/Reimbursement/appContext";


const msalInstance = getAccount();


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <AppProvider>
          <App />
          </AppProvider>
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
