import { Outlet } from "react-router-dom"
import { DRAWER_DATA } from "src/components/drawer/Drawer";
import Drawer from "src/components/drawer/Drawer";
import { Box } from "@mui/material";

const MainLayout = () => {
    return (
        <div className="main">
            <Drawer data={DRAWER_DATA} title="Backoffice" />
            <Box className="content">
                <Outlet />
            </Box>
        </div>
    )
}

export default MainLayout;