import React, { createContext, useState, useContext } from 'react';

interface AppContextType {
    showView: boolean;
    setShowView: (value: boolean) => void;
    resetView: () => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [showView, setShowView] = useState(false);

    const resetView = () => {
        setShowView(false);
    };

    return (
        <AppContext.Provider value={{ showView, setShowView, resetView }}>
            {children}
        </AppContext.Provider>
    );
};