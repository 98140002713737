import React from "react";
import "./Drawer.css"
import {
    List,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useAppContext } from "src/pages/Reimbursement/appContext";

interface DrawerDataGroup {
    text: string;
    members: Array<DrawerDataItem>;
}

export interface DrawerDataItem {
    text: string;
    path: string;
    disabled: boolean;
}

export const DRAWER_DATA: Array<DrawerDataGroup> = [
    {
        text: "Reembolsos",
        members: [
            {
                text: "Captura",
                path: "/reimbursements/entry",
                disabled: true,
            },
            {
                text: "Anulación",
                path: "/reimbursements/anullement",
                disabled: true,
            },
            {
                text: "Deduplicación",
                path: "/reimbursements/deduplication",
                disabled: true,
            },
            {
                text: "Cierre",
                path: "/reimbursements/closing",
                disabled: false,
            },
            {
                text: "Pagos",
                path: "/reimbursements/payments",
                disabled: false,
            }
        ],
    },
    {
        text: "Integración Imed",
        members: [
            {
                text: "Folios",
                path: "/imed/folios",
                disabled: true,
            },
            {
                text: "Proveedores",
                path: "/imed/providers",
                disabled: true,
            }
        ]
    }
]

const DrawerHeader = ({ children }: { children: React.ReactNode }) => (
    <div className="header">
        <span>{children}</span>
    </div>
)

const DrawerGroup = ({ children }: { children: React.ReactNode }) => (
    <div className="group">
        {children}
    </div>
)

const DrawerTitle = ({ children }: { children: React.ReactNode }) => (
    <h6 className="title">
        {children}
    </h6>
)

const DrawerLink = ({ path, children, disabled }: { path: string, children: React.ReactNode, disabled: boolean }) => {

    const {resetView, showView, setShowView} = useAppContext();
    return (
        // <a className="link" href={path}>
        //     {children}
        // </a>

        <>
            <ListItemButton
                sx={{ px: '2rem' }}
                component={Link}
                to={path}
                disabled={disabled}
                onClick={() => {
                    resetView()
                }}
            >
                <ListItemText disableTypography className="linkMenu" primary={children} />
            </ListItemButton>
        </>
    )
}


const Drawer = ({ title, data }: { title: string, data: Array<DrawerDataGroup> }) => (
    <>
        <div className="drawer">
            <DrawerHeader>
                {title}
            </DrawerHeader>
            {data.map((group, i) => (
                <DrawerGroup key={i}>
                    <DrawerTitle>
                        {group.text}
                    </DrawerTitle>
                    <List>
                        {group.members.map((item, j) => (
                            // <DrawerLink key={j} path={item.path}>
                            //     {item.text}
                            // </DrawerLink>

                            <DrawerLink key={j} path={item.path} disabled={item.disabled}>
                                {item.text}
                            </DrawerLink>
                        ))}
                    </List>
                </DrawerGroup>
            ))}
        </div>
    </>
)

export default Drawer;