import React, { createContext, useState } from "react";

const ContextLoader = createContext<{
  globalLoader?: boolean;
  setGlobalLoader: React.Dispatch<React.SetStateAction<boolean>>;
  globalSnackbar?: any;
  setGlobalSnackbar: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      status: string;
      message: string;
    }>
  >;
  checkPeriod?: any;
  setCheckPeriod: React.Dispatch<
    React.SetStateAction<{
      year: number;
      month: number;
    }>
  >;
  contextPeriodData?: any;
  setContextPeriodData: React.Dispatch<
    React.SetStateAction<{
      id: number;
      periodId: number;
      period: string;
    }>
  >;
}>({
  globalLoader: false,
  setGlobalLoader: () => {},
  globalSnackbar: {
    open: false,
    status: "success",
    message: "",
  },
  setGlobalSnackbar: () => {},
  checkPeriod: {
    year: 0,
    month: 0,
  },
  setCheckPeriod: () => {},
  contextPeriodData: {
    id: 0,
    periodId: 0,
    period: "",
  },
  setContextPeriodData: () => {},
});

export const ContextLoaderProvider = ({ children }: { children: any }) => {
  // const [fileContext, setFileContext] = useState({});
  // const [fileStatus, setFileStatus] = useState({
  //   fileStatus: 0,
  //   fileName: "",
  // });

  const [globalLoader, setGlobalLoader] = useState(false);
  const [globalSnackbar, setGlobalSnackbar] = useState({
    open: false,
    status: "success",
    message: "",
  });
  const [checkPeriod, setCheckPeriod] = useState({
    year: 0,
    month: 0,
  });
  const [contextPeriodData, setContextPeriodData] = useState({
    id: 0,
    periodId: 0,
    period: "",
  });

  return (
    <ContextLoader.Provider
      value={{
        globalLoader,
        setGlobalLoader,
        globalSnackbar,
        setGlobalSnackbar,
        checkPeriod,
        setCheckPeriod,
        contextPeriodData,
        setContextPeriodData,
      }}
    >
      {children}
    </ContextLoader.Provider>
  );
};

export default ContextLoader;
