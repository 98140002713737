import { getFreshAccessToken } from "src/utils/msal";
import { Data } from "src/interfaces/ReimbursementInterface/Data.interface"
import { allowOperationIfAuthorizedRole } from "src/helpers/roleManager";

async function getCandidates(date: string | null = null): Promise<Data[]> {
    // await allowOperationIfAuthorizedRole(new Set(["ESENCIAL-BO_ISA_EJECUTIVO", "ESENCIAL-BO_ISA_CIERRE"]));

    const newToken = await getFreshAccessToken()
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${newToken}`);
    myHeaders.append("Content-Type", "application/json");

    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v2/reimbursements/open`;
    // const baseUrl = `/api/v1/reimbursements/open`;
    const url = date !== null ? baseUrl + "?" + new URLSearchParams({ created_before: date }).toString() : baseUrl;
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
    }
    const result = await response.json();
    return result.data;
}



export { getCandidates };