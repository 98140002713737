import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ReimbursementsTable from './ClosingList/reimbursementsTable';
import DashboardReimbursement from './ClosingDashboard/dashboard';
import { Paper, Typography } from '@mui/material';
import Breadcrum from 'src/components/breadcrum/Breadcrum';
import { useSum } from './reimbursementContext';
import { useAppContext } from '../appContext';

export default function ClosingMenu() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const { dataReimbursements, loading, dataDashboard } = useSum();
    const { resetView } = useAppContext();


    return (
        <>
            <Box>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Listado" value="1" />
                            <Tab label="Resumen" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        {/* <Box m={4} lineHeight={1.5} fontFamily="Inter">
                            <Paper elevation={2}> */}
                                <ReimbursementsTable cases={dataReimbursements} loading={loading} reset={resetView}></ReimbursementsTable>
                            {/* </Paper>
                        </Box> */}
                    </TabPanel>
                    <TabPanel value="2">
                        <DashboardReimbursement loading={loading} data={dataDashboard}></DashboardReimbursement>
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    );
}
