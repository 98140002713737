import login_illus from "src/assets/icons/login_illus.svg";
import { filterBox } from "../box/styles";
import { Box, Grid, Stack } from "@mui/material";

const PageNotFound = () => {
  return (
    <Box sx={filterBox} margin={"24px"} p="32px !important">
      <Grid container>
        <Grid item xs={7} display={"flex"}>
          <Stack alignItems={"left"} direction={"column"} margin={"auto"}>
            <label className="Bienvenida">Error 404</label>
            <label className="alSistema">Página no encontrada</label>
            <label className="BienvenidaTexto">
              La URL a la que intentaste acceder no existe.
            </label>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <img alt="img2" src={login_illus}></img>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageNotFound;
