import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green } from '@mui/material/colors';
import createReimbursements from 'src/services/backoffice/createReimbursements';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { CallbackResultError, CallbackResultSuccess } from 'src/interfaces/ReimbursementInterface/CallbackResult.Interface';
import { useAppContext } from 'src/pages/Reimbursement/appContext';

type CallbackResult = CallbackResultSuccess | CallbackResultError;

type CallbackFunction = () => Promise<CallbackResult | null> | null;

interface ConfirmationDialogProps {
    passKey: string;
    callback: CallbackFunction;
    open: boolean;
    onClose: any;
    reset: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    passKey,
    callback,
    open,
    onClose,
    reset
}) => {
    // const [open, setOpen] = React.useState(false);
    const [inputText, setInputText] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [isConfirmed, setIsConfirmed] = React.useState(false);
    const [success, setSuccess] = React.useState<boolean | null>(null);
    




    const handleClose = () => {
        if (!isConfirmed) {
            onClose()
            setMessage("")
            setInputText("")
        }
    };

    const handleFinalDialogClose = () => {
        onClose()
        setMessage("")
        setInputText("")
        setSuccess(null)
        reset()
    };

    const confirmReimbursements = async () => {
        if (passKey === inputText) {


            setMessage('confirmacion exitosa')
            setIsConfirmed(true)

            try {
                const response = await callback();
                if (response?.success) {
                    setSuccess(true);
                } else {
                    setSuccess(false);
                }
            } catch (err) {
                setSuccess(false);
            }

            setTimeout(() => {
                handleClose()
                onClose()
                setIsConfirmed(false)
            }, 2000)
        }
        else {
            setMessage("La palabra ingresada es incorrecta.")
        }

    }

    return (
        <React.Fragment>

            {!isConfirmed && success === null ? (
                <>  <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>Debe confirmar para continuar</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        Para confirmar la acción solicitada ingresar '{passKey}' en el campo de texto.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            name="anular"
                            label=""
                            fullWidth
                            variant="standard"
                            autoComplete="off"
                            onChange={(event) => {
                                setInputText(event.target.value)
                            }}
                        />
                        {message && (
                            <Typography variant="body2" color={'red'}>
                                {message}
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancelar</Button>
                        <Button type="submit" onClick={confirmReimbursements}>Confirmar</Button>
                    </DialogActions>
                </Dialog>
                </>
            ) : success !== null ? (
                <Dialog open={true} onClose={handleFinalDialogClose}>
                    <DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                handleFinalDialogClose()
                                // reset()
                            }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ textAlign: 'center', padding: '20px' }}>
                        <CheckCircleOutlineIcon
                            style={{ fontSize: '75px', color: success ? 'green' : 'red' }}
                        />
                        <Typography variant="h6" color={success ? 'green' : 'red'}>
                            {success ? "Operación exitosa." : "Ha ocurrido un problema con la solicitud."}
                        </Typography>
                    </DialogContent>
                </Dialog>
            ) : (
                <>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogContent style={{ textAlign: 'center', padding: '20px' }}>
                            <CheckCircleOutlineIcon style={{ fontSize: '75px', color: 'green' }} />
                            <Typography variant="h6" color="green" >Confirmación exitosa.</Typography>
                        </DialogContent>
                    </Dialog>
                </>

            )}


        </React.Fragment >
    );
}

export default ConfirmationDialog;