import { getFreshAccessToken, getUserData } from "src/utils/msal";
import { ClosingReimbursements } from "src/interfaces/ReimbursementInterface/ClosingReimbursement.interface";
import { Data } from "src/interfaces/ReimbursementInterface/Data.interface";
import { allowOperationIfAuthorizedRole } from "src/helpers/roleManager";


async function getClosingReimbursements(): Promise<ClosingReimbursements[]> {
    // await allowOperationIfAuthorizedRole(new Set(["ESENCIAL-BO_ISA_EJECUTIVO", "ESENCIAL-BO_ISA_CIERRE"]));

    const newToken = await getFreshAccessToken()
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `bearer ${newToken}`);
    const url = `${process.env.REACT_APP_API_URL}/api/v2/closings`;
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }
        const result = await response.json();
        return result.data;
    } catch (error) {
        throw error;
    }
}

//Perfeccionar un cierre
async function patchClosingReimbursements(ids: string[]): Promise<{ success: boolean, data?: any, error?: any }> {
    const user = await getUserData()
    // await allowOperationIfAuthorizedRole(new Set(["ESENCIAL-BO_ISA_EJECUTIVO", "ESENCIAL-BO_ISA_CIERRE"]));
    const newToken = await getFreshAccessToken()
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `bearer ${newToken}`);
    const raw = JSON.stringify({
        "ids": ids,
        "perfected_by": user.email
    });
    const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v2/closings/perfection`;
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return { success: true, data: result };
}

//Deshacer un pago de folio
async function patchReimbursements(ids: string[]): Promise<any> {
    // await allowOperationIfAuthorizedRole(new Set(["ESENCIAL-BO_ISA_EJECUTIVO", "ESENCIAL-BO_ISA_CIERRE"]));
    const newToken = await getFreshAccessToken()
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `bearer ${newToken}`);
    const raw = JSON.stringify({
        "ids": ids,
        "closing_id": null,
    });
    const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v2/reimbursements/closing`;
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return { success: true, data: result };
}



//Obtener data de un cierre en especifico
async function getClosingIdMembers(id: string): Promise<Data[]> {
    // await allowOperationIfAuthorizedRole(new Set(["ESENCIAL-BO_ISA_EJECUTIVO", "ESENCIAL-BO_ISA_CIERRE"]));

    const newToken = await getFreshAccessToken()
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `bearer ${newToken}`);
    const url = `${process.env.REACT_APP_API_URL}/api/v2/closings/${id}/members`;
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
    }
    const result = await response.json();
    return result.data;
}


//Data para realizar XLSX
async function getClosingReport(id: string): Promise<string> {
    // await allowOperationIfAuthorizedRole(new Set(["ESENCIAL-BO_ISA_EJECUTIVO", "ESENCIAL-BO_ISA_CIERRE"]));
    const newToken = await getFreshAccessToken()
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `bearer ${newToken}`);
    const url = `${process.env.REACT_APP_API_URL}/api/v2/closings/${id}/report`;
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
    }
    const result = await response.json();
    return result.data;
}

async function deleteClosingId(id: string): Promise<any> {
    // await allowOperationIfAuthorizedRole(new Set(["ESENCIAL-BO_ISA_EJECUTIVO", "ESENCIAL-BO_ISA_CIERRE"]));
    const newToken = await getFreshAccessToken()
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `bearer ${newToken}`);
    const url = `${process.env.REACT_APP_API_URL}/api/v2/closings/${id}`;
    const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
    };
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
    }
    const result = await response.json();
    return { success: true, data: result };
}




export { getClosingReimbursements, patchClosingReimbursements, getClosingIdMembers, deleteClosingId, getClosingReport, patchReimbursements };