import login_illus from "src/assets/icons/login_illus.svg";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import "./pages.css";
import { Box, Grid, Stack } from "@mui/material";
import { filterBox } from "../box/styles";

type WelcomeProps = {
  isAuthorized: boolean;
};

const Welcome = ({ isAuthorized }: WelcomeProps) => {
  return (
    <div data-testid="bienvenida">
      <AuthenticatedTemplate>
        {!isAuthorized && (
          <Box sx={filterBox} margin={"24px"} p="32px !important">
            <Grid container>
              <Grid item xs={7} display={"flex"}>
                <Stack alignItems={"left"} direction={"column"} margin={"auto"}>
                  <label className="Bienvenida">No estás autorizado</label>
                  <label className="alSistema">
                    para visualizar esta página
                  </label>
                  <label className="BienvenidaTexto">
                    La cuenta seleccionada no posee el permiso correspondiente.
                  </label>
                </Stack>
              </Grid>
              <Grid item xs={5}>
                <img alt="img2" src={login_illus}></img>
              </Grid>
            </Grid>
          </Box>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box sx={filterBox} marginX={"24px"} marginTop="94px" p="32px !important">
          <Grid container>
            <Grid item xs={7} display={"flex"}>
              <Stack alignItems={"left"} direction={"column"} margin={"auto"}>
                <label className="Bienvenida">Te damos la bienvenida</label>
                <label className="alSistema">al sistema BackOffice Esencial</label>
                <label className="BienvenidaTexto">
                  Aquí podrás gestionar GES, Reembolsos y Otros
                </label>
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <img alt="img2" src={login_illus}></img>
            </Grid>
          </Grid>
        </Box>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default Welcome;
